@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$gradient-z-index: 1;

@function getgradientbackground($end) {
    @return linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) $end);
}

.root {
    margin: 0;
    position: relative;
}

.contentContainer {
    margin-top: -100px;

    @include mq.mq($until: small) {
        margin-top: -56px;
    }

    @include mq.mq($from: medium, $until: medium) {
        margin-top: -120px;
    }
}

.containerMobile {
    padding: 0;
}

.titleContainer {
    position: relative;
}

.heroImageContainer {
    position: relative;
    height: 300px;
    overflow: hidden;
    
    &::before {
        content: '';
        position: absolute;
        z-index: $gradient-z-index;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 100%;
        opacity: 0.68;
        background: getgradientbackground(60%);        
    }

    @include mq.mq($until: small) {
        &::before {
            background: getgradientbackground(100%);
        }
    }

    @include mq.mq($from: medium, $until: medium) {        
        height: 540px;

        &::before {
            background: getgradientbackground(70%);
        }    
    }

    @include mq.mq($from: large) {     
        height: 640px;     
    }
}

.headingSkeleton,
.heading {
    position: absolute;
    bottom: var(--spacing-core-16);
    z-index: $gradient-z-index;
    color: var(--color-foreground-inverted-default);
    padding: 0 var(--spacing-core-16);
    
    @include mq.mq($until: small) {
        bottom: 0;
    }

    @include mq.mq($until: medium) {
        margin: 0;
        padding: 0 var(--spacing-core-5);
    }

    @include mq.mq($from: medium, $until: medium) {
        bottom: var(--spacing-core-8);
    }
}

.headingSkeleton {
    width: 70%;
    height: var(--sizing-core-8);
}

.content {
    position: relative;
    z-index: $gradient-z-index;
}

.recipeDetailsContentContainer {
    position: relative;
    padding: var(--spacing-core-16) var(--spacing-core-16) 0;
    background-color: #fff;
    z-index: 1;

    @include mq.mq($until: medium) {
        margin: 0;
        padding: var(--spacing-core-5) var(--spacing-core-5) 0;
    }
}

.adContainer {
    padding: var(--spacing-core-10) 0;
}
