@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.buttonHeadingContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq.mq($until: medium) {
        flex-direction: column;
    }

    @include mq.mq($until: small) {
        margin-top: var(--spacing-core-10);
    }

    @include mq.mq($from: large) {
        flex-wrap: nowrap;
    }
}

.servingsSkeleton {
    height: var(--sizing-core-10);
    margin-bottom: var(--spacing-core-6);
    border-radius: var(--border-radius-core-circle);
}

.receiptDetailsButton {
    width: 100%;
    margin-top: 18px;
    margin-left: 0;
    margin-bottom: var(--spacing-core-4);
    color: var(--color-foreground-primary-default);
    text-align: center;

    @include mq.mq($from: large) {
        width: auto;
        margin-top: 0;
        margin-left: 5px;
        color: var(--color-foreground-primary-default);
        text-align: center;
    }
}


.bereiding {
    position: relative;

    @include mq.mq($until: small) {
        margin-top: var(--spacing-core-4);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: calc(100% + var(--spacing-core-14));
            left: calc(var(--spacing-core-8) * -1);
            height: 1px;
            background-color: #e0e0e0;
        }
    }
}

.ingredientsSkeleton,
.ingredients {
    @include mq.mq($from: large) {
        max-width: 350px;
    }
}

.ingredientsSkeleton {
   min-height: 400px;
}

.stepSkeleton {
    width: 100%;
}
